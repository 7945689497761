import {useCartStore} from '@/stores/cart.js'
import {resolveUnref} from '@vueuse/core'
import dayjs from 'dayjs'
import {groupBy, map} from 'lodash-es'
import {computed, ref, watch} from 'vue'
import router from '@/router'
import { defineStore } from 'pinia'

export const useSportStore = defineStore('sport', () => {
  const cartStore = useCartStore()

  const games = ref(new Map())
  const fetching = ref(false)
  const expanded = ref([])
  const stuck = ref([])
  const keyword = ref('')

  const leagues = computed(() => {
    let data = Array.from(games.value).map(o => o[1])
    const sport = Number(router.currentRoute.value.params.sport)
    data = data.filter(game => !sport || game.sport_id === sport)
    data = data.filter(game=>{
      return game.league.name_ko.toUpperCase().indexOf(keyword.value.toUpperCase()) >= 0 ||
          game.league.name_en.toUpperCase().indexOf(keyword.value.toUpperCase()) >= 0 ||
          game.home_team.name_ko.toUpperCase().indexOf(keyword.value.toUpperCase()) >= 0 ||
          game.home_team.name_en.toUpperCase().indexOf(keyword.value.toUpperCase()) >= 0 ||
          game.away_team.name_ko.toUpperCase().indexOf(keyword.value.toUpperCase()) >= 0 ||
          game.away_team.name_en.toUpperCase().indexOf(keyword.value.toUpperCase()) >= 0
    })
    data = groupBy(data, game => `${game.league.id}|${game.start_time}`)
    data = map(data, (games, key) => ({
      ...games[0].league,
      key,
      sport_id: games[0].sport_id,
      start_time: games[0].start_time,
      country: games[0].country,
      games
    }))

    data = data.sort((a, b) => dayjs(a.start_time).diff(b.start_time))
    data = data.sort((a, b) => {
      let aIndex = stuck.value.findIndex(i => i === a.key)
      let bIndex = stuck.value.findIndex(i => i === b.key)
      aIndex = aIndex > -1 ? aIndex : 999999
      bIndex = bIndex > -1 ? bIndex : 999999
      return aIndex - bIndex
    })

    return data
  })

  const expandedGames = computed(() => {
    let data = Array.from(games.value).map(o => o[1])
    data = data.filter(game => isExpanded(game.id))
    data = data.sort((a, b) => {
      let aIndex = expanded.value.findIndex(i => i === a.id)
      let bIndex = expanded.value.findIndex(i => i === b.id)
      aIndex = aIndex > -1 ? aIndex : 999999
      bIndex = bIndex > -1 ? bIndex : 999999
      return aIndex - bIndex
    })

    return data
  })

  function isExpanded (id) {
    return expanded.value.includes(id)
  }

  function toggleExpand (id) {
    const index = expanded.value.findIndex(o => o === id)
    if (index > -1) {
      expanded.value.splice(index, 1)
    } else {
      expanded.value.unshift(id)
    }
  }

  function isStuck (id) {
    return stuck.value.includes(id)
  }

  function toggleStack (key) {
    const index = stuck.value.findIndex(o => o === key)
    if (index > -1) {
      stuck.value.splice(index, 1)
    } else {
      stuck.value.unshift(key)
    }
  }

  function syncCart (games) {
    cartStore.items.forEach((item, index) => {
      const game = games.find(o => o.id === item.game.id)
      const market = game?.markets?.find(o => o.id === item.market.id)
      const participant = market?.participants[item.participantIndex]

      if (game && market && participant) {
        const odds = participant.odds.find(o => o.n === item.odds.n)

        cartStore.update(index, {
          game: game,
          market: market,
          participant: participant,
          participantIndex: item.participantIndex,
          odds: odds,
        })
      } else {
        cartStore.remove(index)
      }
    })
  }

  function syncExpanded (games) {
    expanded.value.forEach((key) => {
      if (games.findIndex(o => o.id === key) === -1) {
        toggleExpand(key)
      }
    })
  }

  watch(router.currentRoute, () => {
    expanded.value = []
  })

  watch(games, (newVal) => {
    const resolved = resolveUnref(newVal)
    const games = Array.from(resolved).map(o => o[1])

    syncCart(games)
    syncExpanded(games)
  }, { deep: true })

  return {
    games,
    fetching,
    expanded,
    stuck,
    leagues,
    isExpanded,
    toggleExpand,
    isStuck,
    toggleStack,
    expandedGames,
    keyword
  }
})
